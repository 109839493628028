<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="d-flex mb-2 align-items-center justify-content-start">
            <div class="">
                <img style="width:4rem; margin-right:1rem;" :src="absoluteUrl(item.image)" />
            </div> 
            <div class="">
                <h5 class="mb-0 card-title">{{item.title}}</h5>
            </div>
        </div>
        <p class="card-text small">{{item.description}}</p>
        <div>
            <strong>Brands:</strong> {{item.brands.map(i => i.title).join('|')}}
        </div>
        <hr>
        <slot name="actions"></slot>
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    },      
}
</script>

<style>

</style>