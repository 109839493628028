
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Categories</h5>
      <div class="row mb-4 gy-2">
        <div class="col-sm-12 col-lg-8 d-flex">
          <div class="me-2 d-none d-sm-inline">
            <label class="d-inline-flex mb-0 align-items-center">
              <b-form-select class="form-select" v-model="paginate.perPage" :options="pageOptions"></b-form-select>
            </label>
          </div>
          <b-button variant="primary" class="me-2"
            @click.prevent="popupModalShow = true" type="button">
          <i class="ri ri-add-fill align-bottom"></i> <span class=""> Add Category</span></b-button>
        </div>
        <div class="col-sm-12 col-lg-4">
          <div class="text-md-end mb-0 align-items-center">
            <b-form-input v-model="searchQuery" type="search" placeholder="Search title..." class="form-control"></b-form-input>
          </div>
        </div>
      </div>
      <is-loading v-if="isLoading" />
      <div v-else class="row">
        <div class="col-sm-6 col-lg-4"
          v-for="(item, dIndex) of paginatedItems" :key="dIndex">
            <category-card :item="item">
              <template v-slot:actions>
                <a @click="initEdit(item)" href="javascript:void(0);" class="btn me-3 btn-primary btn-sm">
                  Edit</a>
                <a @click="deleteItem(item.id)" href="javascript:void(0);" class="btn btn-danger btn-sm">
                  Delete</a>
              </template>
            </category-card>
        </div>
      </div>
      <div class="my-4 pagination-rounded">
        <b-pagination v-model="paginate.currentPage" :limit="paginate.limit" class="pagination-rounded" 
        :total-rows="searchedItems.length" :per-page="paginate.perPage"></b-pagination>
      </div>
    </div>
    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update category':'Add new type'"
      title-class="fs-6" @hide="initializeAll()">
      <category-form v-if="popupModalShow" @closeMe="initializeAll()"
      :editMode="editMode" :editItem="editItem" />
    </b-modal>
  </div>
</template>

<script>
import CategoryForm from "@/components/forms/CategoryForm.vue"
import CategoryCard from "@/components/cards/CategoryCard.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "category-list",
  components:{
    CategoryForm,
    CategoryCard,
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      editMode: false,
      editItem: null,
      paginate:{
        currentPage: 1,
        perPage: 12,
        limit:3
      },
      pageOptions: [12, 25, 50, 100],
      searchQuery: '',
    }
  },
  computed: {
    items(){
      return this.$store.state.category.list
    },
    searchedItems(){
      return this.items.filter(i => i.title ? i.title.toLowerCase().includes(this.searchQuery.toLowerCase()) : false )
    },
    paginatedItems(){
      return this.searchedItems.slice(
        this.paginateStart,
        this.paginateEnd
      );
    },
    paginateStart(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage;
    },
    paginateEnd(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  methods: {
    deleteItem(itemId) {
      this.confirmDelete({
        text: "You are about to delete a category",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/categories/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit("category/DELETE_ITEM", itemId)
            }
          })
        }
      });
    },
    fetchItems(){
      this.isLoading = true
      this.$store.dispatch("category/fetchItems")
      .then((_) => this.isLoading = false )
    },
    initEdit(items){
      this.editMode = true;
      this.editItem = {...items}
      this.popupModalShow = true  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
  created(){
    this.fetchItems()
    this.$store.dispatch("brand/fetchBrands")
  },
}

</script>

